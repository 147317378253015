import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { em } from 'polished';
import Img from 'gatsby-image';
import Layout from '../client/common/layout/Layout';
import SEO from '../client/common/layout/SEO';

const IndexStyled = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;

  section {
    background-color: var(--secondaryDarkAlpha-color);
    color: var(--primaryLight-color);
    padding: ${em(15)} ${em(25)};
  }

  a {
    display: block;
  }

  h1 {
    border-bottom: ${em(1)} solid var(--secondaryLight-color);
    padding-bottom: ${em(2)};
    margin-bottom: ${em(8)};
    font-size: 2em;
    color: white;
  }

  .intro {
    padding: 0;

    h1 {
      border: 0;
      padding-bottom: 0;
      margin-bottom: ${em(4)};
    }
  }

  .about {
    grid-area: about;
  }

  .index {
    display: grid;
    grid-gap: ${em(20)};
    grid-auto-columns: 1fr;
    grid-template-areas:
      "tools database"
      "about screenshot"
  }

  .links {
    grid-area: links;

    > * {
      padding: ${em(15)} 0;
    }
  }

  .link {
    font-size: 1.25em;
    font-weight: bold;
    padding-bottom: ${em(4)};
  }

  .tools {
    grid-area: tools;
  }

  .database {
    grid-area: database;
  }

  .screenshot {
    grid-area: screenshot;
  }

  @media (max-width: 645px) {
    .index {
      grid-template-columns: 1fr;
      grid-template-areas:
        "tools"
        "database"
        "about"
        "screenshot";
    }
  }
`;

export const query = graphql`
  query {
    file(absolutePath: {
      regex: "/\\/images\\/wow-classic-screenshot\\.jpg/"
    }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const IndexPage = ({ data, location }) => (
  <Layout>
    <SEO
      title="WoW Classic Tools"
      description="Collection of World of Warcraft Classic tools to help you on your journey back to vanilla WoW."
      keywords={['classic', 'wow', 'world of warcraft', 'wow classic', 'tools']}
      location={location}
      hasSuffix
    />
    <IndexStyled>
      <section className="intro">
        <div className="page-container page-padding">
          <h1>WoW Classic Tools</h1>
          <i>List of tools to help you on your journey back to Vanilla World of Warcraft.</i>
        </div>
      </section>
      <div className="index page-container page-padding">
        <section className="about">
          <h1>About</h1>
          <div>
            This site aims to provide tools for World of Warcraft Classic that I find useful as a
            vanilla player coming back to classic Azeroth. For example, generating a unique
            character name or finding the fastest way to get to Booty Bay from Orgrimmar.
            <br /><br />
            I only played Vanilla WoW seriously as I quit on the release on Burning Cruasade. So thanks
            Classic WoW Team for making this happen! No game has really replaced Vanilla WoW since.
            <br /><br />
            I hope these tools are helpful for you, more tools will be added in the future - enjoy!
          </div>
        </section>

        <section className="tools">
          <h1>Tools</h1>
          <div className="links">
            <div>
              <Link to="/name-generator/">
                <div className="link">&gt; Name Generator</div>
              </Link>
              <i>Generate a unique character name for WoW Classic</i>
            </div>
            <div>
              <Link to="/flight-master/">
                <div className="link">&gt; Flight Master</div>
              </Link>
              <i>Tool for navigating vanilla Azeroth in WoW Classic</i>
            </div>
            <div>
              <Link to="/ding/">
                <div className="link">&gt; Ding</div>
              </Link>
              <i>Minimal levelling helper for WoW Classic</i>
            </div>
          </div>
        </section>

        <section className="database">
          <h1>Database</h1>
          <div className="links">
            <div>
              <Link to="/locations/">
                <div className="link">&gt; Travel Locations</div>
              </Link>
              <i>List of Dungeons, Raids, Capital Cities and Towns in WoW Classic.</i>
            </div>
          </div>
        </section>

        <Img
          className="screenshot"
          fluid={data.file.childImageSharp.fluid}
          alt="A Dwarf Priest in Westfall looking awesome with a tower in the background."
        />
      </div>
    </IndexStyled>
  </Layout>
);

export default IndexPage;
